/***************************************************************************************************
 * equals
 */

declare interface Set<T> {
  equals(another: Set<any>): boolean;
}

Set.prototype.equals = function (another: Set<any>) {
  if (this.size !== another.size) {
    return false;
  }

  return Array.from(this).every((item) => another.has(item));
};
